import React from 'react';
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import bg from "../images/Asset.png"
import bgmobile from "../images/bg-mobile.png"
import "../components/layout.css"

const IndexPage = ({ data }) => {

  const breakpoints = useBreakpoint();
  var image;

  if (breakpoints.mobile) {
    image = bgmobile
  } else {
    image = bg
  }

  return (
    <Layout>
      <Seo title="Carreira Dental Clínic" />
        <div className="fullWidth" style={{ backgroundImage: `url(${image})` }}>

          {breakpoints.md || breakpoints.ipad ? (
            <>
                <div class="container">

                  <div className='wrapper'>
                    <div class="principal">
                      <StaticImage
                        src="../images/logo_preto.svg"
                        loading="eager"
                        className='image'
                        quality={100}
                        formats={["auto", "webp", "avif", "svg"]}
                        alt=""
                      />
                    </div>

                    <div class="linha">
                      {data.globalJson.linktree.map((linktree, l) => (
                        <ul class="links-list">
                          <li>
                            <a href={linktree.link} target="_blank" key={"social" + l}>
                              {linktree.nome}
                            </a>
                          </li>
                        </ul>
                      ))}
                    </div>

                    <div className='redes'>
                      {data.globalJson.social.map((social, l) => (
                        <a href={social.link} target="_blank" key={"rede" + l}>
                          <GatsbyImage image={getImage(social.img)}
                            quality={100}
                            formats={["auto", "webp", "avif", "svg"]}
                            alt={social.alt}
                          />
                        </a>

                      ))}

                    </div>
                  </div>
                </div>
            </>)
            :
            (

              <>

                  <div class="container">

                    <div className='wrapper'>
                      <div class="principal">
                        <StaticImage
                          src="../images/logo_preto.svg"
                          loading="eager"
                          className='image'
                          quality={100}
                          formats={["auto", "webp", "avif", "svg"]}
                          alt=""
                        />
                      </div>

                      <div class="linha">
                        {data.globalJson.linktree.map((linktree, l) => (
                          <ul class="links-list">
                            <li>
                              <a href={linktree.link} target="_blank" key={"social" + l}>
                                {linktree.nome}
                              </a>
                            </li>
                          </ul>
                        ))}
                      </div>

                      <div className='redes'>
                        {data.globalJson.social.map((social, l) => (
                          <a href={social.link} target="_blank" key={"rede" + l}>
                            <GatsbyImage image={getImage(social.img)}
                              quality={100}
                              formats={["auto", "webp", "avif", "svg"]}
                              alt={social.alt}
                            />
                          </a>

                        ))}

                      </div>
                    </div>
                  </div>

              </>
            )}

        </div>


    </Layout >
  )
}

export default IndexPage




export const IndexQuery = graphql`
  query globalJson {
    globalJson {
      info{
        name
        company
      }
      linktree {
        link
        nome
      }
      social{
        alt
        link
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
